import React from "react"

function DonationForm() {
  return (
    <form
      action="/donate"
      name="Donation-Reward-Form"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/donate"
    >
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </p>
      <p>Thank you for your donation!</p>
      <p>
        Please enter the email address associated with your PayPal account so we
        can get your rewards to you!
      </p>
      <p>
        <label>PayPal Email</label>
        <br />
        <input name="email" />
      </p>
      <p>
        <label>What reward you would prefer</label>
        <br />
        <textarea name="requested_rewards" />
      </p>
    </form>
  )
}

export default DonationForm